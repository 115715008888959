import React from "react";
import Menu from "../../common/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Book from "./book";
import Footer from "../../common/Footer";
import girl from "./../../images/cyber-demand-main.jpg";
import fea2 from "./../../images/cyber-demand-lrg-1.jpg";
import fea4 from "./../../images/cyber-demand-lrg-2.jpg";
import fea5 from "./../../images/cyber-demand-lrg-3.jpg";
import Slider from "react-slick";
import "./../solutions/managed-security.css";
import "./../solutions/security-compliance.css";
import item10 from "./../../images/cloud-3d.jpg";
import cloud from "./../../images/portal-bg.webp";
import cutting from "./../../images/cyber-demand-main.jpg";
// apple ani end
function CyberDemand() {
  const imageRef = useRef(null);
  const ref = useRef(null);
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const targets = document.querySelectorAll(".list li");
    gsap.set(".list", { autoAlpha: 1 });
    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });
    const stagger = 0.8;
    const duration = 1;
    gsap.set(".list", { autoAlpha: 1 });
    timeline.set(targets, { transformOrigin: "center bottom" });
    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  // slick slider end
  useEffect(() => {});

  return (
    <>
      <Helmet>
        <title>Cyber on demand | Threat Management Services</title>
        <meta
          name="description"
          content="Cyber On-Demand isn't just a service—it's a strategic approach that transforms cybersecurity from a complex infrastructure into an agile, responsive ecosystem"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Cyber on demand | Threat Management Services | Procal"
        />
        <meta
          property="og:description"
          content="Cyber On-Demand isn't just a service—it's a strategic approach that transforms cybersecurity from a complex infrastructure into an agile, responsive ecosystem "
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/cyber-demand/"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/cyber-demand/"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">Cyber on</h1>

                  <h2 class="title-outline"> Demand</h2>
                  <span class="title--outline green"> Demand </span>
                  <span class="title--outline green"> Demand </span>
                  <span class="title--outline green"> Demand </span>
                  <span class="title--outline green"> Demand </span>
                  <span class="title--outline green"> Demand </span>
                  <span class="title--outline green"> Demand </span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="normal-ptop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Cyber on demand
                </h2>
                <p>
                  Cyber On-Demand isn't just a service—it's a strategic approach
                  that transforms cybersecurity from a complex infrastructure
                  into an agile, responsive ecosystem
                </p>

                <p>
                  In the dynamic digital sphere, traditional cybersecurity
                  models are rapidly giving way to more flexible, responsive
                  approaches. Cyber On-Demand represents a revolutionary
                  paradigm that provides organizations with immediate, scalable,
                  and precisely tailored cybersecurity capabilities. This
                  innovative model transforms cybersecurity from a static
                  infrastructure to a dynamic, adaptive service that can be
                  accessed, configured, and deployed with unprecedented speed
                  and precision.
                </p>
                <p>
                  The core philosophy of Cyber On-Demand is simplicity,
                  flexibility, and immediate responsiveness. By breaking down
                  complex cybersecurity barriers, organizations can now access
                  specialized skills, configuration services, and advanced
                  security capabilities exactly when and how they need them,
                  without the traditional complexities of long-term commitments
                  or extensive infrastructure investments.
                </p>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className="img-fluid" />
            </div>

            <div class="col-md-12 col-lg-12"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea4} className="img-fluid" />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Cyber on demand Solutions
                </h2>
                <p>
                  Cyber on Demand represents the shifting personality and needs
                  of the cybersecurity landscape which are demanding more
                  personalized and customized approach to complex cybersecurity
                  challenges.
                </p>

                <p>
                  Configuration Management On-Demand : A self-service portal
                  that simplifies unauthorized use configurations, enabling
                  organizations to quickly adjust security settings, manage
                  access controls, and implement precise security configurations
                  with minimal friction. This service allows instant
                  modification of security parameters without complex
                  administrative processes.
                </p>

                <p>
                  On-Demand Cyber Skills :A specialized talent marketplace that
                  provides immediate access to world-class cybersecurity
                  professionals. Organizations can rapidly acquire expertise for
                  specific projects, fill critical skill gaps, and bring in
                  specialized knowledge without long-term hiring commitments.
                </p>

                <p>
                  Threat Intelligence Rapid Response : An on-demand service
                  providing immediate access to cutting-edge threat intelligence
                  and rapid response capabilities. Organizations can instantly
                  activate comprehensive threat analysis, mitigation strategies,
                  and expert guidance during potential security incidents.{" "}
                </p>

                <p>
                  Compliance-as-a-Service: A flexible solution that offers
                  on-demand compliance management, enabling organizations to
                  quickly adapt to changing regulatory landscapes. This service
                  provides instant access to compliance experts, automated audit
                  tools, and real-time regulatory tracking.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Strategic Value Proposition
                </h2>
                <p>
                  Cyber On-Demand offers organizations a transformative approach
                  to cybersecurity, characterized by Instant access to
                  specialized security resources, Flexible scaling of
                  cybersecurity capabilities, Cost-effective, pay-as-you-use
                  models, Reduced time-to-implementation and Minimized
                  organizational risk exposure
                </p>
                <p>
                  Organizations adopting Cyber On-Demand can experience up to
                  40% reduction in cybersecurity operational costs, 60% faster
                  response to emerging threats, and significantly improved
                  ability to adapt to rapidly changing technological landscapes.
                </p>

                <p>
                  Procal’s certified and proficient team provide services that
                  are on-par and even beyond the current response essentiality
                  in a future-ready approach aligned with the enterprise’s
                  vision.{" "}
                </p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea5} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <div className="clear-fix"></div>

      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">
            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">
              Procal’s Areas of Expertise
            </h2>
          </div>
        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Instant Scalability </h4>

                  <p>
                    In today's rapidly changing digital landscape, businesses
                    require cybersecurity solutions that can adapt at the speed
                    of technological evolution. Our Cyber On-Demand model
                    provides unprecedented scalability, allowing organizations
                    to Quickly ramp up or down security resources based on
                    immediate needs, Access specialized capabilities without
                    long-term infrastructure investments, Respond to emerging
                    threats with immediate, precise resource allocation and
                    Eliminate traditional constraints of fixed cybersecurity
                    models.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Precise Skill Matching </h4>

                  <p>
                    Unlike traditional talent acquisition approaches, our
                    on-demand service offers hyper-targeted skill deployment of
                    Advanced algorithm-driven matching of cybersecurity
                    professionals, Precise alignment of expert skills with
                    specific organizational challenges, Ability to access niche
                    expertise that might be challenging to recruit internally,
                    Reduced time-to-expertise by connecting with immediately
                    available specialists and Comprehensive vetting process
                    ensuring highest quality of professional capabilities.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Cost-Effective Resource Allocation </h4>

                  <p>
                    Our on-demand model transforms cybersecurity spending from a
                    fixed cost to a strategic investment by our professional
                    practices to Pay only for the exact services and expertise
                    you need, Eliminate overhead costs associated with full-time
                    specialized cybersecurity staff, Reduce training and
                    recruitment expenses, Optimize budget allocation with
                    transparent, flexible pricing models and Convert capital
                    expenditure into predictable operational expenses.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Minimal Implementation Overhead </h4>

                  <p>
                    We've designed our Cyber On-Demand solutions to integrate
                    seamlessly with minimal organizational disruption through
                    Plug-and-play service models, Minimal technical
                    configuration requirements, Rapid onboarding processes,
                    Compatibility with existing technological ecosystems,
                    Reduced implementation complexity and Immediate operational
                    readiness.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Adaptive Flexibility </h4>

                  <p>
                    Our unique approach provides unparalleled adaptability
                    through Real-time adjustment of cybersecurity capabilities,
                    Seamless integration across different technological
                    environments, Customizable service packages, Continuous
                    evolution matching emerging technological trends and
                    Proactive rather than reactive security strategy.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}
      </section>

      {/* whats new section end */}

      <Book />
      <Footer />
    </>
  );
}

export default CyberDemand;
