import React from "react";
import Menu from "../../common/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Book from "./book";
import Footer from "../../common/Footer";
import girl from "./../../images/CyberAutomation-main.jpg";
import fea2 from "./../../images/cyber-automation-lrg-1.jpg";
import fea4 from "./../../images/cyber-automation-lrg-2.jpg";
import fea5 from "./../../images/cyber-automation-lrg-3.jpg";
import Slider from "react-slick";
import "./../solutions/managed-security.css";
import "./../solutions/security-compliance.css";
import item10 from "./../../images/cloud-3d.jpg";
import cloud from "./../../images/portal-bg.webp";
import cutting from "./../../images/CyberAutomation-main.jpg";
// apple ani end
function CyberAutomation() {
  const imageRef = useRef(null);
  const ref = useRef(null);
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const targets = document.querySelectorAll(".list li");
    gsap.set(".list", { autoAlpha: 1 });
    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });
    const stagger = 0.8;
    const duration = 1;
    gsap.set(".list", { autoAlpha: 1 });
    timeline.set(targets, { transformOrigin: "center bottom" });
    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  // slick slider end
  useEffect(() => {});

  return (
    <>
      <Helmet>
        <title>
          Cyber Security Orchestration, Automation and Response Solutions
        </title>
        <meta
          name="description"
          content="SOAR solutions combine incident response, orchestration and automation, and threat intelligence (TI) management capabilities in a single platform."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Cyber Security Orchestration, Automation and Response Solutions | Procal"
        />
        <meta
          property="og:description"
          content="SOAR solutions combine incident response, orchestration and automation, and threat intelligence (TI) management capabilities in a single platform."
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/cyber-automation/"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/cyber-automation/"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">
                    Cyber <br />
                    automation &
                  </h1>

                  <h2 class="title-outline"> orchestration</h2>
                  <span class="title--outline green"> orchestration </span>
                  <span class="title--outline green"> orchestration </span>
                  <span class="title--outline green"> orchestration </span>
                  <span class="title--outline green"> orchestration </span>
                  <span class="title--outline green"> orchestration </span>
                  <span class="title--outline green"> orchestration </span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="normal-ptop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Cyber automation & orchestration
                </h2>
                <p>
                  Automation isn't about replacing human expertise—it's about
                  empowering security teams to focus on strategic innovation by
                  handling complex, repetitive tasks with unparalleled
                  efficiency
                </p>

                <p>
                  Cyber automation and orchestration have emerged as critical
                  strategies for transforming cybersecurity from a reactive
                  approach to a proactive, intelligent defense mechanism. At its
                  core, cyber automation and orchestration represent the
                  seamless integration of advanced technologies, intelligent
                  processes, and strategic workflows that enable organizations
                  to detect, respond to, and mitigate cyber threats with
                  unprecedented speed and precision.
                </p>
                <p>
                  Cyber automation leverages cutting-edge technologies like
                  artificial intelligence and machine learning to automate
                  repetitive security tasks, while orchestration synchronizes
                  complex security tools and processes into a unified, coherent
                  defense strategy. This approach dramatically reduces human
                  error, accelerates threat response times, and provides
                  organizations with a comprehensive, real-time view of their
                  security ecosystem.{" "}
                </p>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className="img-fluid" />
            </div>

            <div class="col-md-12 col-lg-12"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea4} className="img-fluid" />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Cyber automation & orchestration Solutions
                </h2>
                <p>
                  Cyber Automation as a service is recognized as a critical
                  aspect of an enterprise, and in lieu of this fact, Procal
                  Technologies synthesized and designed offerings covering the
                  major challenges under this bracket in the business world.
                </p>

                <p>
                  Identity Lifecycle Automation: Under this category the
                  solutions designed incude IAM Platform Deployment to Implement
                  an IAM application solutions (Microsoft Azure AD, Okta, or
                  SailPoint IdentityIQ), Process Automation to Automate HR
                  onboarding/offboarding processes with identity management,
                  Access Certification to Set up periodic reviews of user access
                  rights, Integration Services to Connect IAM systems with other
                  enterprise applications and Training and Support to Educate
                  staff on identity management best practices and provide
                  support.
                </p>

                <p>
                  SOC & NOC Automation & Orchestration: Solutions include
                  SOC/NOC Automation Platforms to Deploy automation platform
                  (Splunk, IBM QRadar, or Elastic Stack) for automation or
                  develop a custom automation platform using a framework
                  approach, Custom Playbook Development to Create automated
                  response strategies for common incidents, Managed Detection
                  and Response (MDR) that Offer 24/7 monitoring and incident
                  response services, Process Optimization to Analyze and improve
                  existing SOC/NOC processes through automation, and Staff
                  Training to Provide training on the use of automation tools
                  and response procedures.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container 1iot-sec4 pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <p>
                  VM Orchestration: Offerings include Orchestration Tool
                  Deployment to Implement platforms (VMware vRealize
                  Orchestrator, AWS CloudFormation, Quali Torgue), Customized
                  Automation Scripts to Develop scripts to automate VM
                  management tasks, Scalability Solutions set up auto-scaling
                  groups and load balancing configurations, Monitoring and
                  Optimization that Provide tools and services to monitor VM
                  performance and optimize resource usage, Security Integration
                  to Integrate security measures into the VM orchestration
                  process.{" "}
                </p>
                <p>
                  Automated Patch Management: Solutions encompass Patch
                  Management Solutions to deploy patch management application
                  (Microsoft WSUS, Ivanti Patch Management, or SolarWinds Patch
                  Manager), Automated Scheduling to set up automated patch
                  deployment schedules that minimize disruption, Testing
                  Environments to establish testing protocols to ensure patches
                  do not negatively impact systems, Policy Development to assist
                  in creating patch management policies and procedures and
                  Managed Services that Offer ongoing patch management as a
                  service, handling all aspects of the process.{" "}
                </p>

                <p>
                  SOAR (Security Orchestration, Automation, and Response):
                  Assessment Services to Evaluate the client's existing security
                  infrastructure to identify integration points, Platform
                  Deployment to Implement and configure a SOAR platform tailored
                  to the client's environment, Playbook Development to Create
                  customized automated workflows for incident response, Training
                  and Support that Offer training sessions for the client's
                  security team and provide ongoing technical support and
                  Managed SOAR Services that Provide continuous monitoring and
                  management of the SOAR system on behalf of the client.{" "}
                </p>

                <p>
                  Application and Infrastructure Security Automation: Services
                  include Security Tool Integration to Assist clients in
                  integrating security tools into their development and
                  deployment pipelines, Automation Scripts to Develop scripts
                  that use Ansible or Terraform to automate infrastructure
                  security configurations, Policy Enforcement to Implement
                  automated checks to ensure adherence to security policies,
                  Continuous Monitoring that Offer services to continuously
                  monitor applications and infrastructure for security issues
                  and Consulting &Training that Provide expertise on best
                  practices in application and infrastructure security
                  automation.{" "}
                </p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea5} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <div className="clear-fix"></div>

      <section className="container 1iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div className="col-lg-12 col-md-12 mx-auto">
              <h2 className="display-5 lh-1 mb-5 animate-text-2 text-center ">
                Strategic Value and Impact
              </h2>

              <p>
                The strategic value of cyber automation and orchestration
                extends far beyond traditional security measures. By
                implementing intelligent automation, organizations can transform
                their cybersecurity infrastructure from a cost center to a
                strategic enabler of business innovation. These technologies
                enable security teams to shift from manual, time-consuming
                processes to proactive, intelligence-driven defense mechanisms.{" "}
              </p>

              <p>
                Key benefits include rapid threat detection and response,
                reduced operational costs, improved compliance adherence, and
                the ability to handle increasingly complex and sophisticated
                cyber threats. Organizations adopting advanced automation and
                orchestration can experience up to 70% reduction in incident
                response times, 60% decrease in false positive alerts, and
                significant improvements in overall security posture.{" "}
              </p>

              <p>
                Procal’s certified experts aid in delivering robust security
                measures to business’ cyber needs.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* whats new section */}
      <section className="container sec10 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">
            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">
              Procal’s Areas of Expertise
            </h2>
          </div>
        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Intelligent Threat Detection </h4>

                  <p>
                    Procal's advanced threat detection systems utilize machine
                    learning algorithms and AI-powered analytics to identify
                    potential security incidents in real-time. Our intelligent
                    systems can distinguish between genuine threats and false
                    positives, ensuring that security teams focus their efforts
                    on critical vulnerabilities. By continuously learning from
                    global threat intelligence, our automation platforms adapt
                    and evolve, staying ahead of emerging cyber risks.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Integrated Security Orchestration </h4>

                  <p>
                    We design comprehensive security orchestration frameworks
                    that seamlessly integrate diverse security tools and
                    technologies. Our approach ensures that different security
                    solutions communicate effectively, creating a unified
                    defense ecosystem. This integrated approach allows for rapid
                    incident correlation, automated response protocols, and
                    streamlined workflow management across complex IT
                    environments.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Adaptive Incident Response </h4>

                  <p>
                    Our cyber automation solutions transform incident response
                    from a reactive to a predictive model. By developing
                    sophisticated playbooks and automated response mechanisms,
                    we enable organizations to neutralize threats before they
                    can cause significant damage. These adaptive systems can
                    automatically isolate compromised systems, initiate forensic
                    investigations, and implement remediation strategies with
                    minimal human intervention.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Continuous Compliance Management </h4>

                  <p>
                    Procal's automation platforms extend beyond threat
                    management to provide continuous compliance monitoring and
                    reporting. Our solutions automatically track regulatory
                    requirements, generate comprehensive audit trails, and
                    ensure that security configurations consistently meet
                    industry standards. This approach reduces compliance-related
                    risks and provides organizations with real-time visibility
                    into their security and regulatory adherence.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}
      </section>

      {/* whats new section end */}

      <Book />
      <Footer />
    </>
  );
}

export default CyberAutomation;
