import React from "react";
import Menu from "../../common/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Book from "./book";
import Footer from "../../common/Footer";
import girl from "./../../images/access-managment-main.jpg";
import fea2 from "./../../images/access-mngment-lrg-1.jpg";
import fea4 from "./../../images/access-managment-lrg-2.jpg";
import fea5 from "./../../images/certified-information-systems.jpg";
import Slider from "react-slick";
import "./../solutions/managed-security.css";
import "./../solutions/security-compliance.css";
import item10 from "./../../images/cloud-3d.jpg";
import cloud from "./../../images/portal-bg.webp";
import cutting from "./../../images/access-managment-main.jpg";
// apple ani end
function IdentityAccessManagement() {
  const imageRef = useRef(null);
  const ref = useRef(null);
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const targets = document.querySelectorAll(".list li");
    gsap.set(".list", { autoAlpha: 1 });
    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });
    const stagger = 0.8;
    const duration = 1;
    gsap.set(".list", { autoAlpha: 1 });
    timeline.set(targets, { transformOrigin: "center bottom" });
    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  // slick slider end
  useEffect(() => {});

  return (
    <>
      <Helmet>
        <title>identity and access management (IAM) Solutions | Procal</title>
        <meta
          name="description"
          content="Streamline the undertaking with identity and access management experts to help you define and manage solutions across hybrid cloud environments"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="identity and access management (IAM) Solutions | Procal "
        />
        <meta
          property="og:description"
          content="Streamline the undertaking with identity and access management experts to help you define and manage solutions across hybrid cloud environments"
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/identity-access-management/"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/identity-access-management/"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">
                    Identity &<br />
                    Access
                  </h1>

                  <h2 class="title-outline"> Management</h2>
                  <span class="title--outline green"> Management </span>
                  <span class="title--outline green"> Management </span>
                  <span class="title--outline green"> Management </span>
                  <span class="title--outline green"> Management </span>
                  <span class="title--outline green"> Management </span>
                  <span class="title--outline green"> Management </span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="normal-ptop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Identity & Access Management (IAM)
                </h2>
                <p>
                  Identity is the new perimeter. We transform cybersecurity from
                  a defensive barrier into a strategic enabler, helping
                  organizations innovate with confidence
                </p>

                <p>
                  In the digital era, protecting your organization's most
                  critical assets—its data and systems—begins with robust
                  identity and access control. Identity & Access Management
                  (IAM) is a comprehensive security framework that ensures only
                  authorized individuals can access specific resources, at the
                  right times, for the right reasons.
                </p>
                <p>
                  IAM is the first line of defense against unauthorized access,
                  data breaches, and insider threats. It provides granular
                  control over who can access what, when, and how. And this is
                  why it holds great significance in the scene of cybersecurity.
                </p>

                <p>
                  IAM finds its use cases in protecting network infrastructure
                  and customer data from unauthorized access, in ensuring
                  regulatory compliance and prevention of fraudulent access to
                  sensitive financial systems and also to safeguard patient
                  records and maintain strict HIPAA compliance through
                  controlled access and reaching more.
                </p>
                <h4>Identity and Access Management Solutions </h4>
                <p>
                  Procal, under IAM solutions offers its services in
                  consultation, transformation and management, they include:
                </p>

                <ul className="listitmes">
                  <li>Identity Lifecycle Management </li>
                  <li>Access Management & Access Certification </li>
                  <li>Single Sing-on solutions, federation </li>
                  <li>Privileged user access management </li>
                  <li>Access Certification and Attestation </li>
                  <li>Multi-factor Risk based Authentication </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className="img-fluid" />
            </div>

            <div class="col-md-12 col-lg-12"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea4} className="img-fluid" />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Procal Advantage
                </h2>
                <p>
                  At Procal Technologies, we specialize in delivering
                  cutting-edge Identity & Access Management solutions that adapt
                  to your unique business needs.
                </p>

                <p>
                  Our team of cybersecurity professionals brings deep knowledge
                  in designing robust IAM strategies across multiple industries.
                  We've helped global organizations in telecom, finance,
                  banking, and healthcare implement world-class access
                  management solutions. And our team includes certified experts
                  in IAM, including Certified Information Systems Security
                  Professionals (CISSP) and Identity Management Institute (IMI)
                  certified specialists.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="clear-fix"></div>

      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">
            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">
              Procal’s Areas of Expertise
            </h2>
          </div>
        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Custom IAM Solutions </h4>

                  <p>
                    We recognize that each industry faces unique identity and
                    access challenges. Our IAM solutions are meticulously
                    crafted to address specific organizational needs. Our
                    Industry-specific solution examples include in Telecom-
                    Multi-factor authentication systems for network
                    administrators and complex role-based access controls, in
                    Finance & Banking- Advanced biometric authentication and
                    real-time access monitoring for financial systems and in
                    Healthcare- Contextual access management with strict audit
                    trails for electronic health records. We also design
                    client-specific solutions tailored to their business
                    requirements.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Comprehensive IAM Capabilities </h4>

                  <p>
                    Our services under IAM include unique offerings that
                    encompass Single Sign-On (SSO) solutions that enhance user
                    experience while maintaining security, Adaptive
                    authentication using AI and machine learning, Comprehensive
                    identity governance and lifecycle management, and Advanced
                    privileged access management.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Advanced Technologies </h4>

                  <p>
                    Proca stays ahead of the cure through cross-functional
                    collaboration of technologies that involve Zero Trust
                    architecture implementation, Cloud-based IAM solutions,
                    Blockchain-enhanced identity verification and Automated
                    identity lifecycle management.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Key Differentiators </h4>

                  <p>
                    Our expertise spans over Cutting-edge AI-driven threat
                    detection and prevention, Scalable and flexible IAM
                    architectures, Continuous monitoring and adaptive security
                    policies, and Seamless integration with existing IT
                    infrastructure, that are unique to our offerings.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}
      </section>

      {/* whats new section end */}

      <Book />
      <Footer />
    </>
  );
}

export default IdentityAccessManagement;
