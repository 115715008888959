import React from "react";
import { useState } from "react";
import Footer from "../common/Footer";
import "./../pages/company/company.css";
import axios from 'axios';


const Access = ({loginDetails}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = () => {
    let isValid = true;
    let newErrors = { email: "", password: "" };

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address.";
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = "Password is required.";
      isValid = false;
    } else if (formData.password.length < 5) {
      newErrors.password = "Password must be at least 6 characters long.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const login = async () => {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, formData);
      if (res?.status === 200) {
        return res?.data?.data;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const user = await login();
      // console.log(user)
      setIsSubmitted(true);
      setTimeout(() => {
        loginDetails(user)
      }, 1000);
      console.log("Form submitted successfully:", formData);
      // Perform API call or further processing here
    }
  };

  return (
    <>
      <div className="Access-bg">
        <div className="container pb-5 ">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header text-center">
                  <h3>Login</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email
                            ? "is-invalid"
                            : isSubmitted && "is-valid"
                        }`}
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className={`form-control ${
                          errors.password
                            ? "is-invalid"
                            : isSubmitted && "is-valid"
                        }`}
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                    </div>
                  </form>
                  {isSubmitted && !Object.values(errors).some((err) => err) && (
                    <div className="alert alert-success mt-3" role="alert">
                      Login successful!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Access;
