import React from "react";
import Menu from "../../common/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../common/Footer";
import girl from "./../../images/sec-comp.jpg";
import Book from "./book";
import fea2 from "./../../images/managed-left.jpg";
import fea4 from "./../../images/security-manager-cism.jpg";
import fea5 from "./../../images/certified-information-systems.jpg";
import Slider from "react-slick";
import "./../solutions/managed-security.css";
import "./../solutions/security-compliance.css";

import item10 from "./../../images/cloud-3d.jpg";
import cloud from "./../../images/portal-bg.webp";

import cutting from "./../../images/managed-expert.jpg";

// apple ani end
function SecurityCompiance() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll(".list li");

    gsap.set(".list", { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set(".list", { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: "center bottom" });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  // slick slider end
  useEffect(() => {});

  return (
    <>
      <Helmet>
        <title>Framework Security | Cybersecurity Consulting Services </title>
        <meta
          name="description"
          content="At Procal, we offer comprehensive services to help you navigate various frame works and meet compliance obligations effectively. Book Free Consultation Now"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Security Frameworks &amp; Compliance Solutions | Cybersecurity"
        />
        <meta
          property="og:description"
          content="Procal’s process begins with a thorough assessment of your current security posture and regulatory obligations. We work closely with your team to identify potential gaps and vulnerabilities, ensuring a solid foundation for compliance efforts."
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/security-frameworks-compliance/"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/security-frameworks-compliance/"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">Security</h1>
                  <h2 class="title-outline">Frameworks</h2>

                  <h2 class="title-outline">Compliance</h2>
                  <span class="title--outline green">Compliance</span>
                  <span class="title--outline green">Compliance</span>
                  <span class="title--outline green">Compliance</span>
                  <span class="title--outline green">Compliance</span>
                  <span class="title--outline green">Compliance</span>
                  <span class="title--outline green">Compliance</span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="normal-ptop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="container-fluid still pos-relative">
        <blockquote>
          Our team stays abreast of the latest regulatory developments and
          industry best practices, providing ongoing support and guidance to
          keep your compliance program current and effective.
        </blockquote>
      </section>
      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Security Frameworks and Compliance
                </h2>
                <p>
                  At Procal, we understand the importance of both regulatory
                  compliance and industry best practices in defending against
                  ransomware attacks. Our approach to ransomware defense
                  encompasses a combination of compliance adherence and the
                  implementation of proven security best practices.
                </p>

                <p>
                  Procal’s process begins with a thorough assessment of your
                  current security posture and regulatory obligations. We work
                  closely with your team to identify potential gaps and
                  vulnerabilities, ensuring a solid foundation for compliance
                  efforts. Leveraging our extensive expertise in various
                  industries and regulatory frameworks, we develop a customized
                  compliance roadmap that aligns with your business objectives
                  and risk tolerance.
                </p>
                <p>
                  Procal’s approach emphasizes proactive risk management and
                  continuous improvement. We help you implement robust security
                  controls, policies, and procedures to mitigate risks and
                  strengthen your defense against evolving threats. Our team
                  stays abreast of the latest regulatory developments and
                  industry best practices, providing ongoing support and
                  guidance to keep your compliance program current and
                  effective.
                </p>
                <p>
                  Furthermore, we leverage cutting-edge technologies and
                  automation tools to streamline compliance workflows and reduce
                  administrative burdens. From data encryption and access
                  controls to monitoring and incident response, we deploy
                  state-of-the-art solutions that enhance your organization’s
                  security posture while optimizing operational efficiency.
                </p>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className="img-fluid" />
            </div>

            <div class="col-md-12 col-lg-12"></div>
          </div>
        </div>
      </section>

      <section className="container 1iot-sec4 1ptop-standard  pb100 erer">
        <div class="container">
          <div class="row d-flex align-items-center1 ">
            <div class="col-md-8 col-lg-12 ">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <div className="parent-overlaping wid">
                  <div className="left-pan">
                    {" "}
                    <img
                      src={item10}
                      className="img-fluid"
                      style={{ opacity: `0.2` }}
                    />{" "}
                  </div>
                  <div className="right-pan">
                    {" "}
                    <h2 className="display-5 h-1 mb-3 animate-text-2 text-center1 mb-5">
                      Our Approach to Successful Security Compliance
                    </h2>
                    <p>
                      Embark on your compliance journey confidently with
                      Procal’s comprehensive approach. We start with a
                      meticulous evaluation of your current status and
                      regulatory requirements, identifying applicable
                      regulations, standards, and frameworks like GDPR, HIPAA,
                      PCI DSS, and ISO 27001.
                    </p>
                    <p>
                      Our seasoned team of experts shepherds you through each
                      phase of the compliance roadmap, ensuring seamless
                      adherence to regulatory standards. Here’s how we tailor
                      our approach to suit your unique needs:
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-md-4 col-lg-4">

<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Our Approach to Successful Security Compliance</h2>
<p>Embark on your compliance journey confidently with Procal’s comprehensive approach. We start with a meticulous evaluation of your current status and regulatory requirements, identifying applicable regulations, standards, and frameworks like GDPR, HIPAA, PCI DSS, and ISO 27001.</p>
<p>Our seasoned team of experts shepherds you through each phase of the compliance roadmap, ensuring seamless adherence to regulatory standards. Here’s how we tailor our approach to suit your unique needs:</p>




</div> */}
          </div>
        </div>
      </section>
      <div className="clear-fix"></div>
      <section className="container 1iot-sec4 erer">
        <div class="container">
          <div class="row d-flex align-items-center1 mt-5 ">
            <h3 className="text-left">
              Our Approach to Successful Security Compliance
            </h3>
            <p>
              Embark on your compliance journey confidently with Procal’s
              comprehensive approach. We start with a meticulous evaluation of
              your current status and regulatory requirements, identifying
              applicable regulations, standards, and frameworks like GDPR,
              HIPAA, PCI DSS, and ISO 27001.
            </p>
            <p>
              Our seasoned team of experts shepherds you through each phase of
              the compliance roadmap, ensuring seamless adherence to regulatory
              standards. Here’s how we tailor our approach to suit your unique
              needs:
            </p>
            <div className="acc-kontainer">
              <div>
                <input type="radio" name="acc" id="acc1" defaultChecked="" />
                <label htmlFor="acc1">Thorough Compliance Gap Assessment</label>
                <div className="acc-body">
                  <ul>
                    <li>
                      Understanding Your Landscape: Dive deep into your industry
                      intricacies and operational nuances to identify pertinent
                      compliance regulations.
                    </li>
                    <li>
                      Comprehensive Evaluation: Conduct a thorough review of
                      your existing security policies, controls, and tools to
                      unearth potential gaps and risks.
                    </li>
                    <li>
                      Actionable Insights: Provide precise recommendations to
                      enhance compliance posture through meticulous analysis.
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <input type="radio" name="acc" id="acc2" />
                <label htmlFor="acc2">
                  Customized Compliance Roadmap Development
                </label>
                <div className="acc-body">
                  <ul>
                    <li>
                      Tailored Guidance: Craft a personalized roadmap aligned
                      with organizational goals
                    </li>
                    <li>
                      Prioritized Efforts: Focus on high-risk areas for
                      efficient resource allocation
                    </li>
                    <li>
                      Clarity in Execution: Equip teams with a clear path to
                      compliance success.
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <input type="radio" name="acc" id="acc3" />
                <label htmlFor="acc3">Proactive Remediation Support</label>
                <div className="acc-body">
                  <ul>
                    <li>
                      Comprehensive Assistance: Provide support to address
                      compliance gaps confidently
                    </li>
                    <li>
                      Policy Development: Assist in crafting security policies
                      tailored to compliance requirements
                    </li>
                    <li>
                      Technical Expertise: Offer technical support for effective
                      security measures implementation
                    </li>
                    <li>
                      Knowledge Empowerment: Equip teams with tools and
                      knowledge to fortify security posture.
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <input type="radio" name="acc" id="acc4" />
                <label htmlFor="acc4"> Ongoing Compliance Partnership</label>
                <div className="acc-body">
                  <ul>
                    <li>
                      Continuous Support: Navigate evolving compliance landscape
                      with unwavering assistance
                    </li>
                    <li>
                      Regulatory Monitoring: Stay informed about regulatory
                      changes and best practices
                    </li>
                    <li>
                      Regular Audits: Conduct periodic assessments to refine
                      strategies for evolving threats.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* 2nd accordian */}
          <div class="row d-flex align-items-center1 mt-5">
            <h3 className="text-left">
              Advanced Techniques for Security Compliance Mastery
            </h3>
            <p>
              In the realm of security compliance, staying ahead demands more
              than just basic adherence. At Procal, we delve deep into advanced
              techniques to fortify your compliance efforts and elevate your
              organization’s security posture to unprecedented levels.
            </p>
            <div className="acc-kontainer">
              <div>
                <input type="radio" name="acc" id="acc5" defaultChecked="" />
                <label htmlFor="acc5">
                  Cutting-Edge Technology Integration
                </label>
                <div className="acc-body">
                  <ul>
                    <ul>
                      <li>
                        Next-Gen Solutions: Harness the power of cutting-edge
                        technologies such as AI, machine learning, and
                        blockchain to bolster your security controls.
                      </li>
                      <li>
                        Automated Compliance: Streamline compliance workflows
                        with automated tools, reducing manual effort and errors.
                      </li>
                      <li>
                        Continuous Monitoring: Leverage real-time monitoring and
                        threat intelligence systems to detect and respond to
                        security incidents proactively.
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div>
                <input type="radio" name="acc" id="acc6" />
                <label htmlFor="acc6">Adaptive Compliance Strategies</label>
                <div className="acc-body">
                  <ul>
                    <li>
                      AgileF Frameworks: Embrace agile methodologies to adapt
                      your compliance strategies in response to evolving
                      regulatory requirements and emerging threats.
                    </li>
                    <li>
                      Continuous Improvement: Foster a culture of continuous
                      improvement for iterative enhancements to compliance
                      programs.
                    </li>
                    <li>
                      DevSecOps Integration: Integrate security into your DevOps
                      practices to ensure that compliance considerations are
                      embedded throughout the software development lifecycle.
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <input type="radio" name="acc" id="acc7" />
                <label htmlFor="acc7">Continuous Compliance Validation</label>
                <div className="acc-body">
                  <ul>
                    <ul>
                      <li>
                        Automated Auditing: Implement automated auditing and
                        attestation mechanisms to continuously validate
                        compliance with regulatory requirements.
                      </li>
                      <li>
                        Real-Time Reporting: Provide stakeholders with
                        transparent visibility through real-time compliance
                        reports.
                      </li>
                      <li>
                        Regulatory Sandbox: Create a regulatory sandbox
                        environment to safely test new technologies and
                        compliance controls before deployment in production.
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* 3rd accordian */}

          <div class="row d-flex align-items-center1 mt-5">
            <h3 className="text-left">
              Frameworks and Compliance Requirements
            </h3>
            <p>
              In today’s complex regulatory landscape, adhering to industry
              standards and compliance requirements is essential for maintaining
              the security and integrity of your organization’s data. At Procal,
              we offer comprehensive services to help you navigate various
              frameworks and meet compliance obligations effectively.
            </p>
            <p>
              Our team of experts is well-versed in a wide range of frameworks,
              standards, and regulations, including but not limited to:
            </p>
            <div className="acc-kontainer">
              <div>
                <input type="radio" name="acc" id="acc8" defaultChecked="" />
                <label htmlFor="acc8">NIST Cybersecurity Framework (CSF)</label>
                <div className="acc-body">
                  <p>
                    The NIST CSF provides a risk-based approach to managing
                    cybersecurity risk and is widely adopted by organizations
                    across various industries i.e. NIST SP 800-115. We help you
                    align your cybersecurity program with the NIST CSF
                    framework, assess your current security posture, and develop
                    strategies to improve your resilience against cyber threats.
                  </p>
                </div>
              </div>
              <div>
                <input type="radio" name="acc" id="acc9" />
                <label htmlFor="acc9">ISO/IEC 27001</label>
                <div className="acc-body">
                  <p>
                    ISO/IEC 27001 is an international standard for information
                    security management systems (ISMS) that provides a
                    systematic approach to managing sensitive information. We
                    assist you in implementing and maintaining an ISO/IEC
                    27001-compliant ISMS, conducting gap assessments, risk
                    assessments, and internal audits to ensure compliance with
                    the standard.
                  </p>
                </div>
              </div>
              <div>
                <input type="radio" name="acc" id="acc10" />
                <label htmlFor="acc10">
                  {" "}
                  GDPR (General Data Protection Regulation)
                </label>
                <div className="acc-body">
                  <p>
                    GDPR is a comprehensive data protection regulation that
                    applies to organizations operating within the European Union
                    (EU) or processing the personal data of EU residents. We
                    help you assess your GDPR readiness, implement necessary
                    controls and processes, and conduct data protection impact
                    assessments (DPIAs) to ensure compliance with GDPR
                    requirements.
                  </p>
                </div>
              </div>

              <div>
                <input type="radio" name="acc" id="acc11" />
                <label htmlFor="acc11">
                  HIPAA (Health Insurance Portability and Accountability Act)
                </label>
                <div className="acc-body">
                  <p>
                    HIPAA sets standards for protecting sensitive patient health
                    information (PHI) and applies to healthcare providers,
                    health plans, and healthcare clearinghouses. We help you
                    assess your HIPAA compliance, implement safeguards to
                    protect PHI, and develop policies and procedures to ensure
                    compliance with HIPAA regulations.
                  </p>
                </div>
              </div>

              <div>
                <input type="radio" name="acc" id="acc12" />
                <label htmlFor="acc12">
                  PCI DSS (Payment Card Industry Data Security Standard)
                </label>
                <div className="acc-body">
                  <p>
                    PCI DSS is a set of security standards designed to protect
                    payment card data and applies to organizations that process,
                    store, or transmit credit card information. We assist you in
                    achieving and maintaining PCI DSS compliance, conducting
                    assessments, and implementing controls to secure cardholder
                    data.
                  </p>
                </div>
              </div>

              <div>
                <input type="radio" name="acc" id="acc13" />
                <label htmlFor="acc13">
                  {" "}
                  SOC 2 (Service Organization Control 2)
                </label>
                <div className="acc-body">
                  <p>
                    SOC 2 is a framework for assessing the security,
                    availability, processing integrity, confidentiality, and
                    privacy of systems and data at service organizations. We
                    help you prepare for SOC 2 audits, develop controls to meet
                    SOC 2 requirements, and assess your compliance with the
                    framework.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* center section */}
      <div class="container-rze clntfull u-position-21c pt-5 ptop-standard ">
        <div class="row-jsz u-mb-17o u-z-index-vag">
          <div class="col-wjg col-phw"></div>
          <div class="col-wjg col-e7o">
            {/* <h2 class="style-GAVo4" id="style-GAVo4">
                <div class="text-vn6" >At the heart</div>
                <div class="snipcss0-4-5-7" > of our data analytics</div>
                <div class="text-ggq"> solutions</div>
            </h2> */}
          </div>
        </div>
        <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
          <div class="col-wjg col-phw"></div>
          <div class="col-wjg col-z42"></div>
          <div class="col-wjg col-phw"></div>
        </div>
        <div class="row-8oo">
          <div class="col-wjg col-b63"></div>
          <div class="col-wjg col-b63">
            <div class="u-img-a9d image-box-center"></div>
          </div>
          <div class="col-wjg col-b63"></div>
        </div>
        <div class="row-jsz u-mb-17o u-z-index-vag">
          <div class="col-wjg col-s8h clnt2">
            {" "}
            <h6 class="des-5xpt-ggq sidefont">
              Certified Information Security Manager (CISM)
            </h6>
          </div>
          <div class="col-wjg col-e7o">
            <p class="style-CgO1k" id="style-CgO1k"></p>
            <div class="text-vn6 ygqvp">
              <img src={fea4} class="u-img-hcf" />
            </div>

            <p></p>
          </div>
        </div>
        <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
          <div class="col-wjg col-phw clnt">
            <img src={fea5} class="u-img-hcf" />
          </div>
          <div class="col-wjg col-z42">
            <h6 class="des-5xpt-ggq sidefont">
              Certified Information Systems Security Professional (CISSP)
            </h6>
          </div>
          <div class="col-wjg col-phw"></div>
        </div>
        <div class="row-jsz u-z-index-vag">
          <div class="col-wjg col-phw"></div>
          <div class="col-wjg col-e7o">
            <p class="style-RWfkB" id="style-RWfkB"></p>
            <div class="ygqvp text-ggq"></div>
            <div class="ygqvp"></div>
            <p></p>
          </div>
        </div>
      </div>
      {/* center section end */}

      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">
            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">
              Procal’s Areas of Expertise
            </h2>
          </div>
        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Seurity Compliance"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Security Monitoring and Threat Detection</h4>

                  <p>
                    Procal's security experts continuously monitor your network,
                    systems, and applications for potential threats and
                    vulnerabilities. Leveraging advanced security information
                    and event management (SIEM) technology, we identify
                    anomalous activities, intrusion attempts, and potential
                    security breaches in real time. Our proactive approach
                    allows us to detect and respond to security incidents
                    swiftly, minimizing the impact on your organization.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Seurity Compliance"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Incident Response and Resolution</h4>

                  <p>
                    Our rapid incident response team is prepared to take action
                    in the event of a security incident. We follow established
                    incident response protocols, containing and mitigating
                    threats to prevent further damage. Our goal is to minimize
                    downtime, data loss, and financial impact while providing
                    you with a detailed post-incident analysis for continuous
                    improvement.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Seurity Compliance"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Security Management and Policy Enforcement</h4>

                  <p>
                    Procal works closely with your organization to develop and
                    enforce robust security policies tailored to your specific
                    needs. We help you stay compliant with industry regulations
                    and standards while ensuring that your security measures
                    align with your business goals. Our proactive security
                    management approach includes regular security assessments
                    and recommendations to enhance your overall security
                    posture.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Seurity Compliance"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Security Patching and Updates</h4>

                  <p>
                    Procal ensures that all software, operating systems, and
                    applications are promptly updated with the latest security
                    patches to address known vulnerabilities before impacting
                    the network elements.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Seurity Compliance"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Firewall and Network Security</h4>

                  <p>
                    We proactively manage and configure firewalls and other
                    network security endpoints to control and monitor incoming
                    and outgoing network traffic, minimizing the impact on
                    network and productivity for our clients.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Seurity Compliance"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Security Awareness Training</h4>

                  <p>
                    Procal offers security awareness training that educates your
                    employees and stakeholders on best practices, recognizing
                    social engineering attempts, and understanding their role in
                    maintaining a secure environment.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Seurity Compliance"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Compliance and Regulatory Support</h4>

                  <p>
                    Maintaining compliance with industry regulations and
                    standards can be challenging. Procal ensures that your
                    security measures align with these requirements, providing
                    support in achieving and maintaining compliance to protect
                    your reputation and avoid legal consequences.
                  </p>
                  <p>
                    Procal's MSS empowers your organization with the security
                    expertise, technologies, and processes needed to address the
                    ever-growing challenges of the digital world. By partnering
                    with us, you can focus on your core business objectives with
                    confidence, knowing that your data and operations are
                    protected by a dedicated team of security professionals who
                    are committed to your success.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}
      </section>

      {/* whats new section end */}

      <Book />

      <Footer />
    </>
  );
}

export default SecurityCompiance;
