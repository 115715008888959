import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";

function Profile() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState("");

  function generateRandomWord(length) {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  }

  const validate = () => {
    let isValid = true;
    if (!user) {
      setMessage("Email is required.");
      isValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user)) {
      setMessage("Invalid email address.");
      isValid = false;
    } else if(!user.includes('procaltech')){
      setMessage("Invalid email address.");
      isValid = false;
    }
    return isValid;
  };

  const [recentUser, setRecentUser] = useState(null)

  const handleCreate = () => {
    if (validate()) {
      const randomWord = generateRandomWord(10);
      const payload = {
        email: user,
        password: randomWord,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/adduser`, payload)
        .then((res) => {
          getUsers();
          setMessage(res?.data?.data);
          setError(false);
          setUser('');
          setRecentUser(payload)
        }, (err) => {
          setMessage(err.data);
        });
    }
  };
  const getUsers = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/users?nocache=true`)
      .then((res) => {
        setUsers([...res.data]);
      });
  };
  const handleDelete = (ca) => {
    const payload = {
      data: { email: ca },
    };
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/deleteuser`, payload)
      .then((res) => {
        getUsers();
        setMessage(res.data.message);
        setError(false);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="container-fluid p-0">
      {/* <div className="bg-light pt-2 ps-5" style={{ height: '60px' }}>
                <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
            </div> */}
      <div className="row ">
        <div className="col-10 shadow-sm">
          {/* <div className="mx-auto mb-5" style={{width: '200px'}}>
                    <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
                    </div> */}
          {message && !error && (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          )}
          <h5 className="card-title fw-bold pb-3">Add User</h5>
          <form>
            {recentUser ? (
               <div>
               <p>userId : {recentUser?.email}</p> 
                <p>password: {recentUser?.password}</p>
              </div>
            ): ''}
           
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                EmailId
              </label>

              <input
                type="text"
                class="form-control"
                placeholder="emailId"
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={handleCreate}
              className="btn btn-primary col-3"
            >
              Submit
            </button>
          </form>
          <div className="card mt-5">
            <div className="card-header fw-bold">Users</div>
            <ul className="list-group list-group-flush">
              {users.map((ca) => (
                <li
                  key={ca._id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  {ca.email}
                  <span
                    className="badge bg-primary rounded-pill"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(ca.email)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
}

export default Profile;
