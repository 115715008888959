import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import "./addpage.css";
import { useNavigate } from "react-router-dom";
import Editor from "./Editor";

function AddPage({ selectTab, editInfo = false }) {
  const createPagePayload = {
    title: "",
    imgName: "",
    url: "",
    img: "",
    description: "",
    categoryId: "",
    authorId: "",
    overView: "",
  };
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [author, setAuthor] = useState([]);
  const [category, setCategory] = useState([]);
  const [payload, setPayload] = useState(createPagePayload);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const handleUpdatePage = () => {
    axios
        .post(`${process.env.REACT_APP_BASE_URL}/updatePage`, payload)
        .then((res) => {
          // setMessage(res.data.message);
          setError(false);
          selectTab(1);
          document.getElementById("addform").reset();
        })
        .catch((e) => {
          setError(true);
        });
  };

  const handleCreatePage = () => {
    if (payload.title.trim() === "") {
      alert("plase enter title");
    } else if (payload.url.trim() === "") {
      alert("plase enter page url");
    } else if (payload.imgName.trim() === "") {
      alert("plase enter imgname");
    } else if (payload.description.trim() === "") {
      alert("plase enter description");
    } else if (payload.overView.trim() === "") {
      alert("plase enter overView");
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/addPage`, payload)
        .then((res) => {
          // setMessage(res.data.message);
          setError(false);
          selectTab(1);
          document.getElementById("addform").reset();
        })
        .catch((e) => {
          setError(true);
        });
    }
  };
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event?.target?.result);
      };
      reader.readAsDataURL(file);
    });

  const redirecToHomePage = () => {
    // navigate("/");
    const authUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/authorize?response_type=token&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}`;
    window.location.href = authUrl;
  };

  const handleChange = (event) => {
    const name = event.target.name;
    // loginPayload[name] = event.target.value;
    if (name === "title") {
      payload.title = event.target.value;
    } else if (name === "url") {
      payload.url = event.target.value;
    } else if (name === "bannerImg") {
      fileToDataUri(event.target.files[0]).then((dataUri) => {
        payload.img = dataUri;
        setPayload({...payload})
      });
      // let reader = new FileReader();
      // reader.readAsDataURL(event.target.files[0]);
      payload.imgName = event.target.files[0].name;
    } else if (name === "author") {
      payload.authorId = event.target.value;
    } else if (name === "category") {
      payload.categoryId = event.target.value;
    } else if (name === "overView") {
      payload.overView = event.target.value;
    }
    setPayload({...payload})
  };

  const getAuthors = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/allauthor`).then((res) => {
      setAuthor([...res.data]);
    });
  };
  const getCategories = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/allCategory`).then((res) => {
      setCategory([...res.data]);
    });
  };
  useEffect(() => {
    getAuthors();
    getCategories();
    setEditorLoaded(true);
  }, []);


  useEffect(() => {
    if (editInfo && Object.keys(editInfo).length) {
      const { ...payload } = editInfo;
      payload._id = editInfo._id;
      setPayload(payload);
    }else {
      setPayload({...createPagePayload})
    }
  }, [editInfo]);

  
  // const [data, setData] = useState("");

  /* useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        console.log("Authenticated:", isAuthenticated);
        console.log("User info:", user);
      } else {
        console.log("Not authenticated");
      }
    } else {
      console.log("Auth is loading...");
    }
  }, [isAuthenticated, user, isLoading]); */

  return (
    <>
      {/* {isAuthenticated ? ( */}
      <>
        <div className="container-fluid p-0">
          <div className="row ">
            <div className="col-10 shadow-sm leftSideSpace">
              <div className="row">
                <div className="col-md-12">
                  {message && error && (
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  )}
                  {message && !error && (
                    <div className="alert alert-success" role="alert">
                      {message}
                    </div>
                  )}
                  <h5 className="card-title fw-bold pb-3">Add Blog</h5>
                </div>
                <div className="col-md-12">
                  <form id="addform">
                    <div>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Title
                      </label>
                      <input
                        onChange={handleChange}
                        value={payload.title}
                        type="text"
                        className="form-control"
                        name="title"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Page Url
                      </label>
                      <input
                        onChange={handleChange}
                        type="text"
                        value={payload.url}
                        className="form-control"
                        name="url"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Overview
                      </label>
                      <input
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        name="overView"
                        value={payload.overView}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Category
                      </label>
                      <select
                        onChange={handleChange}
                        name="category"
                        className="form-select form-control categorySelect"
                        aria-label="Default select example"
                        value={payload?.categoryId}
                      >
                        <option selected>Select</option>
                        {category.map((cat) => (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Author
                      </label>
                      <select
                        onChange={handleChange}
                        name="author"
                        className="form-select form-control categorySelect"
                        aria-label="Default select example"
                        value={payload?.authorId}
                      >
                        <option selected>Select</option>
                        {author.map((au) => (
                          <option key={au._id} value={au._id}>
                            {au.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label> Banner Image </label>
                      <div className="input-group">
                        <input
                          onChange={handleChange}
                          type="file"
                          name="bannerImg"
                          multiple
                        />
                        <input type="text" className="form-control" readOnly />
                      </div>
                    </div>

                    <div className="blogArea">
                      <Editor
                        name="description"
                        value={payload.description}
                        onChange={(data) => {
                          // setData(data);
                          payload.description = data;
                        }}
                        editorLoaded={editorLoaded}
                      />
                    </div>
                    <div>
                      {editInfo ? (
                        <button
                          type="button"
                          onClick={handleUpdatePage}
                          className="btn btn-primary col-2"
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={handleCreatePage}
                          className="btn btn-primary col-2"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                    <div></div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </>
       {/* ) : loginWithRedirect()}  */}
    </>
  );
}

export default AddPage;
