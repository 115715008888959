import React from "react";
import Menu from "../../common/Menu";
import { useEffect } from "react";
import Footer from "../../common/Footer";
import "./telecom.css";
import tone from "./../../images/tone.png";
import sme from "./../../images/smes.png";
import emerg from "./../../images/emreg-tech.avif";
import auto from "./../../images/automation-left.jpg";
import iphone from "./../../images/iphone-mock.png";
import telcometone from "./../../images/telecom-tone.png";
import Location from "../../common/contact/Location";
import cybersml from "./../../images/lock.jpg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Book from "./book";
import Map from "../../subpages/Map";
function DataAnalytics() {
  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Data Analytics and Insights Services &amp; Solutions | Procal"
        />
        <meta
          property="og:description"
          content="Procal data analytics &amp; insights services &amp; solutions can help you unlock powerful analytics insights by tapping into data you didn&#039;t even know you had."
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/data-analytics-and-insights"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/data-analytics-and-insights"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>

      <Menu />

      <section className="telecom-sec-4 pt100 pb-5" id="data-insight">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-md-5 col-lg-6">
              <div class="rel img-block left-column video-preview wow fadeInRight">
                <img src={auto} alt="video-preview" class="img-fluid" />
              </div>
            </div>
            <div class="col-md-7 col-lg-6">
              <div class="txt-block right-column white-color wow fadeInLeft">
                <h2 class="h2-xs">Data Analytics and Insights</h2>
                <h5>
                  At Procal, we aim to harness the power of data to uncover
                  patterns, generate actionable insights, and make informed
                  decisions. Our cutting-edge solutions leverage AI/ML
                  techniques to collectively embrace the transformative
                  potential of data, enabling businesses to enhance their
                  operations, gain a competitive edge, and unlock valuable
                  opportunities for growth and optimization.
                </h5>
                <button class="pill" type="button">
                  <Link to="/solutions/ai-ml-insights/">AI/ML Insights</Link>
                </button>
                <button class="pill" type="button">
                  <Link to="/solutions/network-analytics/">
                    Network Analytics
                  </Link>
                </button>
                <button class="pill" type="button">
                  <Link to="/solutions/big-data-analytics/">
                    Big Data Analytics
                  </Link>
                </button>
                {/* <button class="pill pill--selected1" type="button">
Computer Vision</button> */}
                <button class="pill" type="button">
                  {" "}
                  <Link to="/solutions/sport-analytics/">
                    Sport Analytics{" "}
                  </Link>{" "}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Book />
      <Footer />
    </>
  );
}

export default DataAnalytics;
