import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Home from "../pages/home/Home";
import Company from "../pages/company/Company";
import Products from "../subpages/Products";
import Testimonial from "../subpages/testimonial";
import Contact from "./../subpages/Contact";
import Access from "./../subpages/Access";
import Snap from "../subpages/Snap";

import Location from "./../common/contact/Location";
import MobileMenu from "./../common/MobileMenu";
import Whowe from "../pages/Whowe";
import Artemis from "../subpages/artemis";
import Announcement from "../subpages/announcement";
import Spot from "../subpages/Spot";
import Map from "../subpages/Map";
import Whatwe from "../pages/whatwe/Whatwe";
import Blog from "../blogs/Blog";

import Fiveg from "../pages/solutions/fiveg";
import Ourclient from "../pages/solutions/ourclient";
import Xrvr from "../pages/solutions/xr-vr";
import MyComponent from "../pages/solutions/MyComponent";
import Solution from "./../pages/solutions/solution";
import Slice from ".././pages/solutions/Slice";
import Iot from ".././pages/solutions/Iot";
import Esim from "../pages/solutions/esim";
import Esimexpert from "../pages/solutions/esimexpert";
import PrivateNetwork from "../pages/solutions/privatenetwork";
import Mobile from "../pages/solutions/mobile";
import Cloud from "../pages/solutions/cloud";
import SmartCity from "../pages/solutions/smartcity";
import Robotics from "./../pages/solutions/robotic";
import Drone from "./../pages/solutions/drone";
import Automotive from "./../pages/solutions/automotive";
import TestAutomation from "../pages/solutions/testautomation";
import RobotProcessing from "../pages/solutions/robot-processing";
import Aiml from "../pages/solutions/aiml";
import OurIndustries from "../pages/solutions/our-industries";
import NetowrkAnalytics from "../pages/solutions/network-analytics";
import SportAnalytics from "./../pages/solutions/sport-analytics";
import BigData from "./../pages/solutions/bigdata";
import Careers from "./../pages/solutions/careers";
import ManagedSecurity from "./../pages/solutions/managed-security";
import SecurityCompiance from "./../pages/solutions/security-compliance";
import PenetrationTesting from "./../pages/solutions/penetration-testing";
import InfraSecurityControls from "./../pages/solutions/infrastructure-security-controls";
import CyberResilience from "./../pages/solutions/cyber-resilience";
import IdentityAccessManagement from "./../pages/solutions/identity-access-management";
import GovernanceRiskCompliance from "./../pages/solutions/grc";
import CyberDemand from "./../pages/solutions/cyber-demand";
import CyberAutomation from "./../pages/solutions/cyber-automation";
import Ransomware from "./../pages/solutions/ransomware-health-checks";
import Applyform from "./../pages/solutions/applyform";
import Vulnerability from "./../pages/solutions/vulnerability";
import Manhattan from "./../pages/casestudy/revolutionizing-manhattan";
import Securing from "./../pages/casestudy/securingnetwork";
import Savelives from "./../pages/casestudy/savelives";
import Enhancing from "./../pages/casestudy/enhancing";
import Revolutionizing from "./../pages/casestudy/revolutionizing";
import Transforming from "./../pages/casestudy/transforming";
import Tracking from "./../pages/casestudy/tracking";
import Streamlining from "./../pages/casestudy/streamlining";
import Comcasts from "./../pages/casestudy/comcasts";
import Diagnostics from "./../pages/casestudy/diagnostics";
import Caselisting from "./../pages/casestudy/caselisting";
import Threat from "./../pages/solutions/threat-intelligence";
import EndPoint from "../pages/solutions/end-point";
import ApplicationSecurity from "../pages/solutions/application-security";
import ServerSecurity from "../pages/solutions/server-security";
import Art_1 from "../blogs/articles/art_1";
import DigitalBanking from "../subpages/digital-banking";
import DigitalTransformation from "../subpages/digital-transformation-solutions";
import Hardware from "./../subpages/hardware-engg";
import Navbarmenu from "./../subpages/newmenu";
import Dashboard from "./../admin/Dashboard/Dashboard";
import Author from "../admin/Author";
import Category from "../admin/Category";

import AddPage from "../admin/AddPage";

import FinancialServices from "./../pages/fintech/financial-services";
import Regulatory from "./../pages/fintech/regulatory-remediation";
import TechnologyModernization from "./../pages/fintech/technology-modernization";
import BuildingOperational from "./../pages/fintech/building-operational";
import RobustControls from "./../pages/fintech/robust";
import FFIEC from "./../pages/fintech/ffiec";
import Cocreate from "./../pages/cocreate/cocreate";
import Telecom from "../pages/solutions/Telecom";
import Financial from "../pages/solutions/financial-ser";
import Cybers from "../pages/solutions/cyber-security";
import CybersLeads from "../pages/solutions/cybersecurity-leads";
import TelecomLeads from "../pages/solutions/telecom-leads";
import EmergingLeads from "../pages/solutions/emerging-tech-leads";
import AutomationLeads from "../pages/solutions/automation-testing-leads";

import DataLeads from "../pages/solutions/data-analytics-insights-leads";
import DigitalLeads from "../pages/solutions/digital-transformation-leads";
import Automation from "../pages/solutions/automation-testing";
//import DataAnalytics  from '../pages/solutions/automation-testing';
import DataAnalytics from "../pages/solutions/data-analytics";
import EmergingTechnologies from "../pages/solutions/emerging-technologies";
import Fintech from "../pages/solutions/fintech";
import Book from "../pages/solutions/book";
import Campaign from "../pages/campaign/campaign";

import PageNotFound from "./contact/pagenotfound";
import ThankYou from "./contact/thankyou";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/admin" element={<Dashboard />} />
      <Route path="/category" element={<Category />} />

      <Route path="/author" element={<Author />} />
      <Route path="/addpage" element={<AddPage />} />
      {/* <Route path="/blogs" element={<AllBlog />} />
        <Route path="/blog/:id" element={<Blogs />} /> */}
      <Route
        path="/case-studies/securing-web-applications-global-insurance-company/"
        element={<Manhattan />}
      />
      <Route
        path="/case-studies/ransomware-attacks-on-hospitals/"
        element={<Savelives />}
      />
      <Route
        path="/case-studies/environmental-monitoring-system/"
        element={<Securing />}
      />
      <Route path="/enhancing" element={<Enhancing />} />
      <Route
        path="/case-studies/5g-internet-of-things"
        element={<Revolutionizing />}
      />
      <Route
        path="/case-studies/transforming-atts-operations-for-technological-advancement-and-cost-efficiency/"
        element={<Transforming />}
      />
      <Route
        path="/case-studies/leader-tracking-systems-turning-data-information-school-leadership-turning-data-information"
        element={<Tracking />}
      />
      <Route
        path="/case-studies/commodity-trades-with-advanced-automation"
        element={<Streamlining />}
      />
      <Route
        path="/case-studies/ extended-reality-5g-network-performance-diagnostics-tool/"
        element={<Diagnostics />}
      />
      <Route
        path="/case-studies/cloud-technology-for-real-time-adaptability"
        element={<Comcasts />}
      />

      <Route path="/casestudy" element={<Caselisting />} />
      <Route path="/products" element={<Products />} />
      {/* <Route path="/company" element={<Whowe />} /> */}
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/Access" element={<Access />} />
      <Route path="/products/snap-automation" element={<Snap />} />
      <Route path="/hardware-engg" element={<Hardware />} />
      <Route path="/digitalbanking" element={<DigitalBanking />} />
      <Route
        path="/digital-transformation-solutions"
        element={<DigitalTransformation />}
      />
      <Route path="/products/artemis" element={<Artemis />} />
      <Route path="/products/spot-finder/" element={<Spot />} />
      <Route path="/Location" element={<Location />} />
      <Route path="/thankyou" element={<ThankYou />} />
      <Route path="/who-we-are/" element={<Whowe />} />
      <Route path="/Map" element={<Map />} />
      <Route path="/what-we-do/" element={<Whatwe />} />
      <Route path="/who-we-are/" element={<Whowe />} />

      <Route path="/blog/:id" element={<Art_1 />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/solution" element={<Solution />} />
      <Route path="/solutions/5g/" element={<Fiveg />} />
      <Route path="/ourclient" element={<Ourclient />} />
      <Route path="/solutions/xr-ar-vr/" element={<Xrvr />} />
      <Route path="/MyComponent" element={<MyComponent />} />
      <Route path="/solutions/slicing/" element={<Slice />} />
      <Route path="/solutions/iot/" element={<Iot />} />
      <Route path="/solutions/esim/" element={<Esim />} />
      <Route path="/esimexpert" element={<Esimexpert />} />
      <Route path="/solutions/private-networks/" element={<PrivateNetwork />} />
      <Route path="/solutions/mobile-os/" element={<Mobile />} />
      <Route path="/solutions/cloud/" element={<Cloud />} />
      <Route path="/solutions/smart-city/" element={<SmartCity />} />
      <Route path="/solutions/robotics/" element={<Robotics />} />
      <Route path="/solutions/drones/" element={<Drone />} />
      <Route path="/solutions/automotive/" element={<Automotive />} />
      <Route
        path="/solutions/automation-and-remote-testing/"
        element={<TestAutomation />}
      />
      <Route path="/solutions/ai-ml-insights/" element={<Aiml />} />
      <Route
        path="/solutions/robot-processing-automation/"
        element={<RobotProcessing />}
      />
      <Route
        path="/solutions/network-analytics/"
        element={<NetowrkAnalytics />}
      />
      <Route path="/solutions/big-data-analytics/" element={<BigData />} />
      <Route path="/solutions/sport-analytics/" element={<SportAnalytics />} />
      <Route
        path="/solutions/managed-security-services/"
        element={<ManagedSecurity />}
      />
      <Route
        path="/solutions/security-frameworks-compliance/"
        element={<SecurityCompiance />}
      />
      <Route
        path="/solutions/penetration-testing/"
        element={<PenetrationTesting />}
      />

      <Route
        path="/solutions/infrastructure-security-controls/"
        element={<InfraSecurityControls />}
      />

      <Route
        path="/solutions/cyber-resilience/"
        element={<CyberResilience />}
      />

      <Route
        path="/solutions/identity-access-management/"
        element={<IdentityAccessManagement />}
      />

      <Route path="/solutions/grc/" element={<GovernanceRiskCompliance />} />
      <Route path="/solutions/cyber-demand/" element={<CyberDemand />} />

      <Route
        path="/solutions/cyber-automation/"
        element={<CyberAutomation />}
      />
      <Route
        path="/solutions/ransomware-health-checks/"
        element={<Ransomware />}
      />
      <Route path="/vulnerability" element={<Vulnerability />} />
      <Route path="/solutions/telecom" element={<Telecom />} />
      <Route path="/solutions/fiancial-ser" element={<Financial />} />
      <Route path="/solutions/cyber-security" element={<Cybers />} />
      <Route path="/solutions/cybersecurity-leads" element={<CybersLeads />} />
      <Route path="/solutions/telecom-leads" element={<TelecomLeads />} />
      <Route
        path="/solutions/emerging-tech-leads"
        element={<EmergingLeads />}
      />
      <Route
        path="/solutions/automation-testing-leads"
        element={<AutomationLeads />}
      />
      <Route
        path="/solutions/data-analytics-insights-leads"
        element={<DataLeads />}
      />
      <Route
        path="/solutions/digital-transformation-leads"
        element={<DigitalLeads />}
      />
      <Route path="/solutions/book" element={<Book />} />
      <Route path="/solutions/fintech" element={<Fintech />} />
      <Route path="/solutions/automation-testing" element={<Automation />} />
      <Route
        path="/solutions/data-analytics-and-insights"
        element={<DataAnalytics />}
      />
      <Route
        path="/solutions/emerging-technologies"
        element={<EmergingTechnologies />}
      />
      <Route path="/threat-Intelligence" element={<Threat />} />
      <Route path="/end-point-security/" element={<EndPoint />} />
      <Route path="/application-security/" element={<ApplicationSecurity />} />
      <Route path="/server" element={<ServerSecurity />} />
      <Route path="/our-industries" element={<OurIndustries />} />
      <Route path="/testimonial" element={<Testimonial />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/newmenu" element={<Navbarmenu />} />
      <Route
        path="/solutions/financial-services/"
        element={<FinancialServices />}
      />
      <Route
        path="/solutions/regulatory-remediation/"
        element={<Regulatory />}
      />
      <Route
        path="/solutions/technology-modernization-and-automation/"
        element={<TechnologyModernization />}
      />
      <Route
        path="/solutions/robust-controls-design/"
        element={<RobustControls />}
      />
      <Route
        path="/solutions/building-operational-resilience/"
        element={<BuildingOperational />}
      />
      <Route path="/solutions/ffiec-assessment/" element={<FFIEC />} />
      <Route path="/products/cocreate/" element={<Cocreate />} />
      <Route path="/MobileMenu" element={<MobileMenu />} />
      <Route path="/pages/campaign/campaign/" element={<Campaign />} />
      <Route path="/announcement" element={<Announcement />} />
      <Route path="/modal/:id" element={<Applyform />} />
      <Route path="/Pagenotfound" element={<PageNotFound />} />

      {/* Path for 404 NotFound */}
      <Route path="/404" element={<PageNotFound />} />

      {/* Redirect to /404 for undefined paths */}
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}

export default Router;
