import React from "react";
import Menu from "../../common/Menu";
import { useEffect } from "react";
import Footer from "../../common/Footer";
import "./telecom.css";
import emergsml from "./../../images/emer-tech-sml.jpg";
import tone from "./../../images/tone.png";
import sme from "./../../images/smes.png";
import emerg from "./../../images/emreg-tech.avif";
import auto from "./../../images/automation-left.jpg";
import iphone from "./../../images/iphone-mock.png";
import telcometone from "./../../images/telecom-tone.png";
import Location from "../../common/contact/Location";
import cybersml from "./../../images/lock.jpg";
import Book from "./book";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Map from "../../subpages/Map";
function EmergingTechnologies() {
  return (
    <>
      <Helmet>
        <title>Emerging Technologies Consulting Services & Solutions</title>
        <meta
          name="description"
          content="We aim to leverage cutting-edge technology to revolutionize the way we perceive, interact, and operate in our surroundings. Our solutions collectively embody innovation, connectivity, and the pursuit of new frontiers in technology-driven solutions."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Emerging Technologies Consulting Services &amp; Solutions | Procal"
        />
        <meta
          property="og:description"
          content="We aim to leverage cutting-edge technology to revolutionize the way we perceive, interact, and operate in our surroundings. Our solutions collectively embody innovation, connectivity, and the pursuit of new frontiers in technology-driven solutions."
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/Emerging-Technologies"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/emerging-technologies"
        />
      </Helmet>

      <Menu />

      <section className="telecom-sec-3 pt100 pb-5" id="emerging">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-md-7 col-lg-6">
              <div class="txt-block right-column white-color wow fadeInLeft">
                <h2 class="h2-xs">Emerging Technologies</h2>
                <h5>
                  At Procal, we push the boundaries of technology to drive
                  transformative change and enhance various aspects of
                  industries and human experiences. We aim to leverage
                  cutting-edge technology to revolutionize the way we perceive,
                  interact, and operate in our surroundings. Our solutions
                  collectively embody innovation, connectivity, and the pursuit
                  of new frontiers in technology-driven solutions.
                </h5>
                <button class="pill" type="button">
                  <Link to="/solutions/xr-ar-vr/">XR/AR/VR</Link>
                </button>{" "}
                <br />
                <button class="pill" type="button">
                  <Link to="/solutions/robotics/">Robotics</Link>
                </button>
                <button class="pill" type="button">
                  <Link to="/solutions/automotive/">Automotive</Link>
                </button>
                <button class="pill" type="button">
                  <Link to="/solutions/drones/">Drones</Link>
                </button>
                <button class="pill" type="button">
                  <Link to="/solutions/drones/">Drones</Link>
                </button>
                <button class="pill" type="button">
                  <Link to="/hardware-engg">Hardware Engineering</Link>
                </button>
              </div>
            </div>
            <div class="col-md-5 col-lg-6">
              <div class="rel img-block left-column video-preview wow fadeInRight">
                <img src={emergsml} alt="video-preview" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Book />
      <Footer />
    </>
  );
}

export default EmergingTechnologies;
