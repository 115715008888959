import React from 'react';
import Menu from './../../common/Menu';
import  { useEffect } from 'react';
import Footer from './../../common/Footer'
import './telecom.css';
import tone from './../../images/tone.png'
import sme from './../../images/smes.png'
import emerg from './../../images/emreg-tech.avif'
import auto from './../../images/automation-left.jpg'
import iphone from './../../images/iphone-mock.png'
import telcometone from './../../images/telecom-tone.png'
import Location from '../../common/contact/Location';
import cybersml from './../../images/lock.jpg'
import { Helmet } from "react-helmet";
import Book from './book';
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";

import Map from '../../subpages/Map';
function Telecom() {


    
  
return (
<>
<title>Custom Telecom Software Development Services | USA, UK, UAE</title>
<meta name="description" content="Our comprehensive range of services ensures that your organization stays connected and operates efficiently in today's dynamic telecommunications landscape." />
<Helmet>

<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Custom Telecom Software Development Services | USA, UK, UAE" />
	<meta property="og:description" content="Our comprehensive range of services ensures that your organization stays connected and operates efficiently in today&#039;s dynamic telecommunications landscape." />
	<meta property="og:url" content="https://www.procaltech.com/solutions/telecom/" />
	<meta property="og:site_name" content="Procal Technologies" />
	<link rel="canonical" href="https://www.procaltech.com/solutions/telecom/" />
</Helmet>
<Menu />


<section className='telecom-sec-2 pt100 py-5 pb-5' id='telecom'>
<div class="container">
<div class="row d-flex align-items-center">
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight" >
<img src={tone} alt="video-preview" class="img-fluid" />
</div>
</div>
<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >Telecom Solutions</h2>
<h5>At Procal, we provide holistic telecom solutions that encompass voice, data, and network services. With our expertise in designing, implementing, and managing state-of-the-art communication systems, we cater to the ever-changing requirements of businesses. Our comprehensive range of services ensures that your organization stays connected and operates efficiently in today's dynamic telecommunications landscape.
</h5>

<button class="pill" type="button" ><Link to="/solutions/5g/">5G Solutions</Link></button>
<button class="pill" type="button"><Link to="/solutions/slicing/">Slicing</Link></button>
<button class="pill" type="button"><Link to="/solutions/iot/">IoT</Link></button>
<button class="pill" type="button">
<Link to="/solutions/esim/">eSIM</Link></button>
<button class="pill" type="button"><Link to="/solutions/private-networks/">Private Networks</Link></button>     <br />
<button class="pill" type="button"><Link to="/solutions/mobile-os/">Mobile OS</Link></button>
<button class="pill" type="button">
<Link to="/solutions/cloud/">Cloud</Link></button>
<button class="pill" type="button">

<Link to="/solutions/smart-city/">Smart City</Link></button>


</div>
</div>


</div>
</div>
</section>
<Book/>

<Footer />
</>
)
}

export default Telecom
