import React from "react";
import Menu from "../../common/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Book from "./book";
import Footer from "../../common/Footer";
import girl from "./../../images/router-infra.png";

import fea2 from "./../../images/security-controls-lrg-1.jpg";
import fea4 from "./../../images/security-philosophy-lrg-2.jpg";
import fea5 from "./../../images/certified-information-systems.jpg";
import Slider from "react-slick";
import "./../solutions/managed-security.css";
import "./../solutions/security-compliance.css";

import item10 from "./../../images/cloud-3d.jpg";
import cloud from "./../../images/portal-bg.webp";

import cutting from "./../../images/router-infra.png";

// apple ani end
function InfraSecurityControls() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll(".list li");

    gsap.set(".list", { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set(".list", { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: "center bottom" });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  // slick slider end
  useEffect(() => {});

  return (
    <>
      <Helmet>
        <title>Infrastructure Security Controls Solutions | Procal</title>
        <meta
          name="description"
          content="Infrastructure security is the practice of protecting critical systems and assets against physical and cyber threats."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Infrastructure Security Controls Solutions | Procal"
        />
        <meta
          property="og:description"
          content="Infrastructure security is the practice of protecting critical systems and assets against physical and cyber threats."
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/infrastructure-security-controls/
"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/infrastructure-security-controls/
"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">
                    Infrastructure
                    <br /> Security{" "}
                  </h1>

                  <h2 class="title-outline">Controls </h2>
                  <span class="title--outline green">Controls </span>
                  <span class="title--outline green">Controls </span>
                  <span class="title--outline green">Controls </span>
                  <span class="title--outline green">Controls </span>
                  <span class="title--outline green">Controls </span>
                  <span class="title--outline green">Controls </span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="normal-ptop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Infrastructure & Security Controls
                </h2>
                <p>
                  Infrastructure security isn't about building walls—it's about
                  creating an intelligent, adaptive ecosystem that anticipates,
                  identifies, and neutralizes threats before they emerge
                </p>

                <p>
                  In today's hyperconnected world, a company’s infrastructure is
                  its most important asset. Infrastructure & Security Controls
                  represent the foundational shield that protects your digital
                  ecosystem from sophisticated cyber threats, ensuring
                  operational continuity and strategic resilience.
                </p>
                <p>
                  Robust infrastructure security goes beyond traditional
                  defense—it's about creating an adaptive, intelligent
                  protective framework that evolves with emerging technological
                  landscapes. Procal realizes the need and potential of this
                  technology as a service and emphasizes on strategic Focus
                  Areas which include Comprehensive network protection, Advanced
                  endpoint security, Resilient infrastructure architecture and
                  Proactive threat mitigation strategies
                </p>

                <p>
                  <strong>Infrastructure & Security Controls Solutions</strong>
                </p>

                <p>
                  Procal Services in this domain include to Define, Classify,
                  Policy, Evaluate, Deploy, Integrate, Customize Cloud, Data
                  Center and Hosts . We cover System Security , Perimeter
                  Security and Advanced Security. The offerings constitute -{" "}
                </p>

                <ul className="listitmes">
                  <li>Management of End to End Infrastructure Security </li>
                  <li>Incident Detection, Analysis & Recommendation </li>
                  <li>Compliance Reporting </li>
                  <li>Administration, Processes based Responses </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className="img-fluid" />
            </div>

            <div class="col-md-12 col-lg-12"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea4} className="img-fluid" />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Procal's Infrastructure Security Philosophy
                </h2>
                <p>
                  Our security represents a comprehensive strategy designed for
                  the purpose of protecting data, applications, and
                  infrastructure linked with cloud computing environments. As
                  businesses increasingly move to cloud platforms, a robust
                  security becomes the most critical task.
                </p>

                <p>
                  Key Dimensions of our Security measures include Data
                  Protection, Access Management, Threat Detection, Compliance
                  Adherence and Risk Mitigation.
                </p>
                <p>
                  Procal’s Strategic Value Proposition ensures Business
                  Continuity through uninterrupted operations, Risk Mitigation
                  by Proactively identifying and neutralizing potential threats,
                  Cost Efficiency by Reducing potential financial losses from
                  breaches, Regulatory Compliance through adherence to industry
                  standards and Technological Advancement enabling secure
                  digital transformation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="clear-fix"></div>

      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">
            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">
              Procal’s Areas of Expertise
            </h2>
          </div>
        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Comprehensive Assessment </h4>

                  <p>
                    Our service ensures Holistic cloud environment evaluation,
                    Identifying potential vulnerabilities, Mapping security
                    infrastructure, Customized risk assessment framework that
                    are intelligent, adaptive, and precisely aligned with your
                    organizational ecosystem. Our services targeted Security
                    Domains include Network Infrastructure Protection through
                    implementation of advanced intrusion prevention systems,
                    Endpoint Security in developing comprehensive device
                    protection strategies, Cloud Security Controls that Secure
                    distributed and hybrid cloud environments and Data Center
                    Security by creating resilient and intelligent protection
                    mechanisms
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Advanced Technological Capabilities: </h4>

                  <p>
                    We leverage lates technology progress and stand as pioneers
                    of digital transformation even in the field of
                    Infrastructure security. Our adoptions include Zero Trust
                    security architecture, Automated threat detection systems,
                    Behavioral analytics for anomaly identification, and
                    Real-time security posture assessment. We also focus on
                    expert Implementation of our capabilities through Seamless
                    security integration, minimal operational disruption,
                    Scalable security frameworks and Certified professional
                    deployment.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Cutting-Edge Technologies </h4>

                  <p>
                    We adopt advanced tech in the market for delivering robust
                    security services, especially in the extremely vulnerable
                    digital world of today. Our expertise spans over AI-powered
                    threat detection, Machine learning-enhanced security
                    analytics, Automated security orchestration and Advanced
                    encryption and data protection technologies.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Unique Value Propositions </h4>

                  <p>
                    Our team of capable experts offer unique proficiency in the
                    field of infrastructure security. Our distinctive services
                    stay as a testament to our propositions which include
                    Predictive threat intelligence, Adaptive security
                    frameworks, Continuous vulnerability management and
                    Integrated security ecosystem design. Our strength lies in
                    our Industry-specific customization, 24/7 security
                    monitoring, Global threat intelligence integration and
                    Certified security professionals.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}
      </section>

      {/* whats new section end */}

      <Book />
      <Footer />
    </>
  );
}

export default InfraSecurityControls;
