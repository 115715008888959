import React from "react";
import Menu from "../../common/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Book from "./book";
import Footer from "../../common/Footer";
import girl from "./../../images/cyber-resilience-main.png";
import fea2 from "./../../images/cyber-resilience-lrg-1.jpg";
import fea4 from "./../../images/cyber-resilience-lrg-2.jpg";
import fea5 from "./../../images/certified-information-systems.jpg";
import Slider from "react-slick";
import "./../solutions/managed-security.css";
import "./../solutions/security-compliance.css";
import item10 from "./../../images/cloud-3d.jpg";
import cloud from "./../../images/portal-bg.webp";
import cutting from "./../../images/cyber-cloud-2.jpg";
// apple ani end
function CyberResilience() {
  const imageRef = useRef(null);
  const ref = useRef(null);
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const targets = document.querySelectorAll(".list li");
    gsap.set(".list", { autoAlpha: 1 });
    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });
    const stagger = 0.8;
    const duration = 1;
    gsap.set(".list", { autoAlpha: 1 });
    timeline.set(targets, { transformOrigin: "center bottom" });
    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  // slick slider end
  useEffect(() => {});

  return (
    <>
      <Helmet>
        <title>Cyber Resilience & Recovery Services | Cyber Protection </title>
        <meta
          name="description"
          content="Cyber Resilience Solutions offer strong protection, quick recovery, and continuous security, making it the smart choice to secure your digital future"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Cyber Resilience & Recovery Services | Cyber Protection "
        />
        <meta
          property="og:description"
          content="Cyber Resilience Solutions offer strong protection, quick recovery, and continuous security, making it the smart choice to secure your digital future"
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/cyber-resilience/"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/cyber-resilience/"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">
                    Cyber <br />
                    Resilience &
                  </h1>

                  <h2 class="title-outline">Recovery</h2>
                  <span class="title--outline green">Recovery </span>
                  <span class="title--outline green">Recovery </span>
                  <span class="title--outline green">Recovery </span>
                  <span class="title--outline green">Recovery </span>
                  <span class="title--outline green">Recovery </span>
                  <span class="title--outline green">Recovery </span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="normal-ptop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Cyber Resilience & Recovery
                </h2>
                <p>
                  Our Cyber Resilience Solutions offer strong protection, quick
                  recovery, and continuous security, making it the smart choice
                  to secure your digital future.
                </p>

                <p>
                  Cyber Resilience & Recovery is the process of preparing for,
                  responding to, and recovering from cyber incidents to ensure
                  business continuity and minimal disruption. This is extremely
                  relevant to the current IT landscape where cyber threats are
                  evolving at an unprecedented pace, posing serious risks to
                  businesses across all industries.
                </p>
                <p>
                  Cyber resilience not only shields the organization from
                  external threats like ransomware, phishing attacks, and data
                  breaches but also equips them to adapt and thrive despite
                  these challenges.
                </p>

                <p>
                  <strong>Industry-Specific Relevance </strong>
                </p>

                <p>
                  Telecom: Safeguard critical network infrastructure and ensure
                  uninterrupted service delivery.
                </p>

                <p>
                  Finance & Banking: Protect customer financial data, comply
                  with strict regulations, and mitigate financial fraud risks.{" "}
                </p>

                <p>
                  Healthcare: Secure sensitive patient data, maintain compliance
                  with HIPAA, and ensure the continuity of patient care
                  services.{" "}
                </p>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className="img-fluid" />
            </div>

            <div class="col-md-12 col-lg-12"></div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea4} className="img-fluid" />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Cyber Resilience & Recovery Solutions
                </h2>
                <p>
                  Incident Response: Design and Implement plans to respond
                  effectively to security incidents.
                </p>

                <p>
                  AV/Malware Testing: Regularly test antivirus and anti-malware
                  solutions to ensure effectiveness.
                </p>
                <p>
                  Data Backup and Recovery: Implement strong data backup and
                  recovery programs to reduce data loss.
                </p>

                <p>
                  BCP/DR: Develop and sustain business continuity and disaster
                  recovery plans.{" "}
                </p>
                <p>
                  DDOS Mitigation: Implement measures to protect against
                  distributed denial-of-service attacks.{" "}
                </p>
                <p>
                  Network Segmentation: Divide networks into smaller parts to
                  minimize the impact of potential breaches.{" "}
                </p>
                <p>
                  Data Vault: Tertiary environment to securely store and protect
                  sensitive data.{" "}
                </p>
                <p>
                  Tabletop exercises: Simulate real-world security incidents to
                  test response plans and identify areas for improvement.{" "}
                </p>

                <h4>Procal Advantage </h4>
                <p>
                  At Procal Technologies, we strive to help businesses navigate
                  the intricate cybersecurity landscape with confidence.
                  Choosing the right partner for cyber resilience is critical
                  for a business's success. Here’s why we stand apart -{" "}
                </p>
                <p>
                  A Decade of Expertise: With over 10 years of experience, we’ve
                  built a reputation for delivering innovative, reliable, and
                  tailored cybersecurity solutions.{" "}
                </p>
                <p>
                  Trusted by Leading Industries: Our partnerships with global
                  leaders in telecom, finance, banking, and healthcare highlight
                  our commitment to excellence.{" "}
                </p>
                <p>
                  Recognition & Certifications: We hold industry-recognized
                  certifications, ensuring our solutions meet the highest
                  standards of security and reliability.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="clear-fix"></div>

      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">
            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">
              Procal’s Areas of Expertise
            </h2>
          </div>
        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Tailored Solutions </h4>

                  <p>
                    Procal services expand over a range of industries. In
                    Telecom, our solutions offer Proactive threat detection
                    systems to ensure 99.9% uptime and protect customer data. In
                    Finance & Banking, our cyber resilience ensures AI-driven
                    fraud detection and secure data management frameworks. In
                    Healthcare, we offer Comprehensive ransomware recovery plans
                    and real-time monitoring of sensitive data.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Cutting-Edge Technologies </h4>

                  <p>
                    Our Cyber Resilience & Recovery Solutions leverage advanced
                    tech like AI-powered threat intelligence platforms for
                    real-time response, Cloud-based recovery systems ensuring
                    minimal downtime and Blockchain solutions to enhance data
                    integrity and security.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Industry-Specific Expertise </h4>

                  <p>
                    We understand the nuances of telecom, finance, banking, and
                    healthcare, ensuring our solutions are compliant and
                    effective.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Procal’s Proven Differentiators </h4>

                  <p>
                    Our services are imbibed with latest technologies and are
                    concurrent with the current business trends like Advanced AI
                    and ML tools for threat detection, Scalable solutions
                    designed to grow with your business and Dedicated 24/7
                    support from certified cybersecurity experts.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}
      </section>

      {/* whats new section end */}

      <Book />
      <Footer />
    </>
  );
}

export default CyberResilience;
