/* eslint-disable jsx-a11y/anchor-is-valid */
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./../../images/logo-350.png";
import { BrowserRouter as Router, Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import AddPage from "../AddPage";
import Author from "../Author";
import Category from "../Category";
import Jobs from "../Jobs";
import Skills from "../Skills";
import Joblocation from "../JobLocation";
import AdminBlogs from "../AdminBlogs";
import Access from "./../../subpages/Access";
import Profile from "./../Profile";

function Dashboard() {
  const [useInfo, setUseInfo] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [loadPageType, setLoadPageType] = useState("blogs");
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = (type) => {
    setLoadPageType(type);
  };
  const renderPage = () => {
    switch (loadPageType) {
      case "blogs":
        return <AdminBlogs />;
      case "blog":
        return <AddPage />;
      case "author":
        return <Author />;
      case "category":
        return <Category />;
      case "jobs":
        return <Jobs />;
      case "skills":
        return <Skills />;
      case "JobLocation":
        return <Joblocation />;
      case "profile":
        return <Profile />;
      default:
        break;
    }
  };
  const redirectToLogin = () => {
    navigate("/");
  };

  const handleLoginDetails = (d) => {
    setUseInfo(d);
    setIsAuthenticated(true);
    sessionStorage.setItem("info", btoa(JSON.stringify(d)));
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUseInfo(null);
    sessionStorage.clear();
    redirectToLogin();
  };

  const isUserAuthenticated = () => {
    const u = sessionStorage.getItem("info");
    if (u) {
      const uinfo = u && JSON.parse(atob(u));
      setUseInfo(uinfo);
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    isUserAuthenticated();
  }, []);

 /*  useEffect(() => {
    if(isAuthenticated) {
        getBlogs();
    }
  }, [isAuthenticated]); */

  return (
    <>
      {isAuthenticated && useInfo.hasOwnProperty("email") ? (
        <div className="container-fluid p-0">
          <div className="bg-light pt-2 ps-5 topLogobar">
            <Link to="/">
              <img src={logo} />
            </Link>
            <div className="logOutBtn pt-3">
              <div className="dropdown">
                <FontAwesomeIcon
                  className="actionIcon"
                  onClick={() => setShowMenu(!showMenu)}
                  icon={faUser}
                />
                <ul
                  className="dropdown-menu profileList"
                  style={{ display: showMenu ? "block" : "none" }}
                >
                  {/* <li onClick={(e) => handleProfile(e)}>
                    <a className="dropdown-item" href="#">
                      Profile
                    </a>
                  </li> */}
                  <li>{useInfo?.email}</li>
                  <li onClick={() => handleLogout()}>
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5 ps-5 pe-5">
            <div className="col col-md-3 mb-3 dashbordtab">
              <ul className="nav nav-tabs tabs-left sideways flex-column">
                <li onClick={() => handleMenuClick("blogs")}>
                  <a
                    className={
                      loadPageType === "blogs"
                        ? "nav-link fw-bold active"
                        : "nav-link fw-bold"
                    }
                  >
                    Blogs
                  </a>
                </li>
                <li onClick={() => handleMenuClick("author")}>
                  <a
                    className={
                      loadPageType === "author"
                        ? "nav-link fw-bold active"
                        : "nav-link fw-bold"
                    }
                  >
                    Author
                  </a>
                </li>
                <li onClick={() => handleMenuClick("category")}>
                  <a
                    className={
                      loadPageType === "category"
                        ? "nav-link fw-bold active"
                        : "nav-link fw-bold"
                    }
                  >
                    Category
                  </a>
                </li>

                <li onClick={() => handleMenuClick("jobs")}>
                  <a
                    className={
                      loadPageType === "jobs"
                        ? "nav-link fw-bold active"
                        : "nav-link fw-bold"
                    }
                  >
                    Jobs
                  </a>
                </li>

                <li onClick={() => handleMenuClick("skills")}>
                  <a
                    className={
                      loadPageType === "skills"
                        ? "nav-link fw-bold active"
                        : "nav-link fw-bold"
                    }
                  >
                    Skills
                  </a>
                </li>
                {useInfo.role === "admin" ? (
                  <li onClick={() => handleMenuClick("profile")}>
                    <a
                      className={
                        loadPageType === "profile"
                          ? "nav-link fw-bold active"
                          : "nav-link fw-bold"
                      }
                    >
                      Profile
                    </a>
                  </li>
                ) : (
                  ""
                )}

                <li onClick={() => handleMenuClick("JobLocation")}>
                  <a
                    className={
                      loadPageType === "JobLocation"
                        ? "nav-link fw-bold active"
                        : "nav-link fw-bold"
                    }
                  >
                    Job Location
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-9 col-md-9 1shadow-sm p-3">
              <div className="tab-content" id="myTabContent">
                {renderPage()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Access loginDetails={handleLoginDetails} />
      )}
    </>
  );
}

export default Dashboard;
