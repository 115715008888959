import React from "react";
import Menu from "../../common/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Book from "./book";
import Footer from "../../common/Footer";
import girl from "./../../images/grc-main.jpg";
import fea2 from "./../../images/grc-lrg-1.jpg";
import fea4 from "./../../images/grc-lrg-2.jpg";
import fea5 from "./../../images/grc-lrg-3.jpg";
import Slider from "react-slick";
import "./../solutions/managed-security.css";
import "./../solutions/security-compliance.css";
import item10 from "./../../images/cloud-3d.jpg";
import cloud from "./../../images/portal-bg.webp";
import cutting from "./../../images/grc-main.jpg";
// apple ani end
function GovernanceRiskCompliance() {
  const imageRef = useRef(null);
  const ref = useRef(null);
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const targets = document.querySelectorAll(".list li");
    gsap.set(".list", { autoAlpha: 1 });
    const timeline = gsap.timeline({
      defaults: {
        overwrite: "auto",
        ease: "power1",
      },
      scrollTrigger: {
        end: "+=300%",
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: "top top",
        trigger: ".list",
      },
    });
    const stagger = 0.8;
    const duration = 1;
    gsap.set(".list", { autoAlpha: 1 });
    timeline.set(targets, { transformOrigin: "center bottom" });
    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=20%",
        end: "bottom top-=20%",
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: "power2.out",
    });

    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
      duration: 0.5, // Adjust duration
      ease: "power4.in", // Adjust easing
      scrollTrigger: {
        trigger: ".erer",
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`,
          ];
          gsap.set(imageRef.current, {
            clipPath: `polygon(${points.join(", ")})`,
          });
        },
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-head-outline",
        start: "top top",
        end: "bottom top",
        scrub: 0.2,
      },
      ease: "power.inOut",
    });

    tl.to(".title-outline", {
      yPercent: 100,
    })
      .to(
        ".title--hello",
        {
          yPercent: 100,
        },
        0
      )
      .to(
        ".title--outline",
        {
          yPercent: (i) => (i + 1) * 15 + 100,
        },
        0
      )
      .from(
        ".image-box img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box-center img",
        {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: ".outline-box-center",
            start: "top center",
            scrub: 1,
          },
          ease: "power.inOut",
        },
        0
      )
      .from(
        ".image-box0 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box0",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box1 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box1",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box2 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box2",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      )

      .from(
        ".image-box3 img",
        {
          yPercent: 25,
          ease: "none",
          scrollTrigger: {
            trigger: ".image-box3",
            start: "top center",
            scrub: 2,
          },
          ease: "power.inOut",
        },
        0
      );
  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  // slick slider end
  useEffect(() => {});

  return (
    <>
      <Helmet>
        <title>Governance, Risk and Compliance (GRC) Services | Procal</title>
        <meta
          name="description"
          content="Manage IT risk by establishing governance structures that increase cybersecurity maturity with an integrated governance, risk and compliance (GRC) approach."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Governance, Risk and Compliance (GRC) Services | Procal"
        />
        <meta
          property="og:description"
          content="Manage IT risk by establishing governance structures that increase cybersecurity maturity with an integrated governance, risk and compliance (GRC) approach."
        />
        <meta
          property="og:url"
          content="https://www.procaltech.com/solutions/grc/"
        />
        <link
          rel="canonical"
          href="https://www.procaltech.com/solutions/grc/"
        />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />

      <section className="relative1  five-sec-2 start">
        <div className="container">
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}

              <div className="col-lg-7">
                <div class="section-head__title flex-basis-666">
                  <h1 class="title-outline">
                    Governance
                    <br />
                    Risk
                  </h1>

                  <h2 class="title-outline"> Compliance </h2>
                  <span class="title--outline green"> Compliance </span>
                  <span class="title--outline green"> Compliance </span>
                  <span class="title--outline green"> Compliance </span>
                  <span class="title--outline green"> Compliance </span>
                  <span class="title--outline green"> Compliance </span>
                  <span class="title--outline green"> Compliance </span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="sidetext">
                  <div class="image-box">
                    <img src={girl} className="img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="normal-ptop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="container 1iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Governance, Risk, and Compliance (GRC)
                </h2>
                <p>
                  Governance isn't about controlling risks—it's about unlocking
                  strategic potential. We turn compliance into your
                  organization's most powerful catalyst for innovation
                </p>

                <p>
                  In an increasingly complex regulatory environment, effective
                  Governance, Risk, and Compliance (GRC) is not just a
                  necessity—it's a strategic advantage. GRC represents a
                  holistic approach to aligning organizational strategies,
                  managing risks, and ensuring regulatory adherence. GRC enables
                  organizations to navigate complex regulatory landscapes,
                  mitigate potential risks, and create a culture of strategic
                  transparency.
                </p>
                <p>
                  It’s Impact is profound through ensurance of regulatory
                  compliance and management of complex communication
                  infrastructure risks, in navigate stringent financial
                  regulations and maintaining robust risk management frameworks.
                  It is also used to maintain patient data privacy and
                  compliance with evolving healthcare regulations.
                </p>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className="img-fluid" />
            </div>

            <div class="col-md-12 col-lg-12"></div>
          </div>
        </div>
      </section>

      <section className="container 1iot-sec4 ptop-standard  pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea4} className="img-fluid" />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Governance, Risk, and Compliance (GRC) Solutions
                </h2>
                <p>
                  Under GRC & Advisory, Procal offers how to govern risk and
                  compliance processes across silos and stakeholders. The
                  services include:
                </p>

                <p>
                  Common Controls Framework (CCF): Standardized approach to
                  identify, assess, and implement security controls to mitigate
                  risks and ensure compliance with industry standards and
                  regulations.
                </p>

                <p>
                  Privacy (GDPR, CCPA): Implement controls to protect personal
                  data and ensure compliance with global privacy regulations.
                </p>

                <p>
                  Regulatory Readiness: Assess regulatory requirements and
                  develop strategies to achieve and maintain compliance.
                </p>

                <p>
                  Audit & Compliance Support: Conduct audits and assessments to
                  verify compliance with security standards and regulations.
                </p>

                <p>
                  Strategy Roadmaps: Develop strategic plans to align
                  cybersecurity initiatives with business objectives.{" "}
                </p>

                <p>
                  GRC Tool Implementation: Deploy and manage GRC tools to
                  streamline compliance processes and improve risk management.{" "}
                </p>

                <p>
                  CISO Services: Leverage experienced CISO expertise to guide
                  your cybersecurity strategy and improve overall security
                  posture.{" "}
                </p>
                <p>
                  On Demand Cyber Skills: Access specialized cybersecurity
                  talent to address specific needs and skill gaps.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container iot-sec4 pb-5 erer">
        <div class="container">
          <div class="row d-flex align-items-center1">
            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">
                  Procal Approach
                </h2>
                <p>
                  At Procal Technologies, we view GRC as more than a compliance
                  checklist—it's a strategic roadmap for organizational
                  resilience. Our approach begins with Comprehensive Expertise
                  where our team brings deep insights into regulatory
                  complexities across multiple industries. With Global
                  Perspective, Procal supports organizations in developing
                  robust governance frameworks that transcend traditional
                  compliance models.Certified Professionals of Our team includes
                  experts with leading certifications in governance, risk
                  management, and compliance strategies.
                </p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea5} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <div className="clear-fix"></div>

      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">
            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">
              Procal’s Areas of Expertise
            </h2>
          </div>
        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Tailored Governance Frameworks </h4>

                  <p>
                    Our practices & solutions of Regulatory compliance mapping
                    and risk assessment are designed for complex network
                    infrastructures. Tailored to comprehensive risk management
                    strategies of our solutions address financial and
                    operational risks. And Integrated compliance solutions take
                    care of data protection and operational risks.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Advanced Capabilities </h4>

                  <p>
                    Intelligent risk prediction and mitigation strategies,
                    Automated compliance monitoring and reporting, Real-time
                    regulatory landscape tracking, and Integrated governance
                    dashboards are some of the advanced tech and trends of
                    Procal’s GRC Solutions.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Transformative Implementation & Results </h4>

                  <p>
                    Our unique offerings helped our clients redesign their
                    governance framework, reducing compliance-related incidents
                    by 75%. Through our unique GRC, we support our partners &
                    clients in developing a predictive risk management model
                    that proactively identified potential regulatory challenges
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">
                    <div className="box03 bg-coral-medium list-video">
                      <img
                        src={cutting}
                        className="card-img1"
                        alt="Penetration Testing"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-7 col-12 align-items-center">
                  <h4>Our Distinctive Value Proposition </h4>

                  <p>
                    Choosing Procal means transforming compliance from a burden
                    to a strategic opportunity. Our Unique Differentiators
                    include AI-powered risk intelligence, Dynamic, adaptable
                    compliance frameworks, Predictive regulatory trend analysis
                    and Holistic approach to organizational governance. We
                    leverage Strategic Technologies of Machine learning-enhanced
                    risk modeling, Cloud-based GRC platforms, Advanced analytics
                    for regulatory insights and Integrated compliance management
                    systems to enhance businesses.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}
      </section>

      {/* whats new section end */}

      <Book />
      <Footer />
    </>
  );
}

export default GovernanceRiskCompliance;
